import React, { useEffect, useState, useContext } from 'react'
import { GlobalContext } from '../GlobalContext'
import { json, Link } from 'react-router-dom'
import { validateForm } from '../utils/validateForm'
import { subscribe } from "../utils/pubsub"
import Modal from '../ui-elements/Modal'
import Spinner from '../ui-elements/Spinner'
import Number from '../formfields/Number'
import Button from '../formfields/Button'
import Text from '../formfields/Text'
import { clear } from 'console'


type Props = {
  handleLogout: AnyFunction
}

function SuperAdmin({
  handleLogout
}: Props) {



  const makeTables = () => {
    const payload = JSON.stringify({
      action: "superAdmin",
      subaction: "makeTables"
    })
    sendMessageToWebsocket(payload)
  }


  const {
    tableData,
    userData,
    fridges,
    currentOrganisation,
    switchCurrentOrganisation,
    sendMessageToWebsocket,
    checklistSetupFields,
    setChecklistSetupFields,
    checklistQuestionFields,
    setChecklistQuestionFields,
    activeFeatures,
    expiredFeatures,
    loggedIn,
    loginUrl
  } = useContext(GlobalContext)

  const initialFormData = {
    "OrganisationName": { "value": '', "required": true, "type": "text" },
  }
  const [formFields, setFormFields] = useState<ObjectStringKeyAnyValue>(initialFormData)
  const [showCloneModal, setShowCloneModal] = useState(false)
  const [savingProgress, setSavingProgress] = useState(false)
  const [cloningProgress, setCloningProgress] = useState<string | null>(null)


  useEffect(() => {
    // if the clone modal is closed, reset the form field data
    if (!showCloneModal) {
      setFormFields(initialFormData)
    }
  }, [showCloneModal])

  const handleChange = (key: string, value: any) => {
    const newFieldValues: ObjectStringKeyAnyValue = { ...formFields }
    newFieldValues[key] = { ...newFieldValues[key], 'value': value }
    setFormFields(newFieldValues)
  }


  const makeAuditSheet = async () => {
    setSavingProgress(true)
    const payload = JSON.stringify({
      action: "superAdmin",
      subaction: "makeAuditSheet",
    })
    sendMessageToWebsocket(payload)
    const unsubscribe = subscribe("makeAuditSheetResponse", data => {
      setSavingProgress(false)
      window.location.href = data.downloadUrl
      unsubscribe()
    })
  }



  const cloneOrg = async () => {
    if (formFields && formFields.OrganisationName && formFields.OrganisationName.value) {
      setShowCloneModal(false)
      setCloningProgress('inProgress')
      const payload = JSON.stringify({
        action: "superAdmin",
        subaction: "cloneOrg",
        OrganisationName: formFields.OrganisationName.value
      })
      sendMessageToWebsocket(payload)
      const unsubscribe = subscribe("cloneOrgResponse", data => {
        setCloningProgress('complete')
        unsubscribe()
      })
    } else {
      console.log('⛔️ No organisation name provided')
    }
  }




  return <div className={`flex flex-col items-center`}>
    <div className={`max-w-3xl w-full p-5 flex flex-col gap-5 items-start`}>
      <h2 className={`text-4xl font-bold text-brandblue `}>Superadmin stuff</h2>

      {showCloneModal && <Modal>
        <div className={`w-full flex flex-col gap-4`}>

          <Text
            labelText={'New organisation name:'}
            formFields={formFields}
            handleChange={handleChange}
            fieldName={'OrganisationName'}
          />
          <div className={`w-full flex flex-row gap-2`}>
            <Button
              onClick={() => setShowCloneModal(false)}
              text={'Cancel'}
              size={'big'}
              variant={'gray'}
            />
            <Button
              onClick={(e: any) => validateForm(e, formFields, setFormFields, cloneOrg)}
              text={'Clone'}
              size={'big'}
              variant={'primary'}
            />
          </div>
        </div>
      </Modal>}


      <div className={`w-full flex flex-col gap-2`}>
        <Button
          onClick={makeTables}
          text={'Make org tables'}
          size={'big'}
          variant={'primary'}
        />

        <Button
          onClick={makeAuditSheet}
          text={'Make audit sheet'}
          size={'big'}
          variant={'primary'}
        />

        <Button
          onClick={() => setShowCloneModal(true)}
          text={'Clone this organisation'}
          size={'big'}
          variant={'primary'}
        />


      </div>
    </div>

    {savingProgress && <Modal
      showCloseButton={true}
      setShowModal={setSavingProgress}
    ><Spinner><p>In progress...</p></Spinner>
    </Modal>}

    {cloningProgress && <Modal
      showCloseButton={true}
      setShowModal={setCloningProgress}
      closeButtonValue={null}
    >
      {cloningProgress === 'complete' ?
        <div className={`flex flex-col gap-2`}>
          <p>Organisation cloned. You will need to log out and log back in to access your new organisation.</p>

          <Button
            onClick={handleLogout}
            text={'Log out'}
            size={'big'}
            variant={'primary'}
          />
        </div> :
        <Spinner>Cloning organisation. This may take several minutes.</Spinner>
      }
    </Modal>}
  </div>

}

export default SuperAdmin