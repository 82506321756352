import React, { useEffect, useState, useContext } from 'react'
import {
  BrowserRouter as Router,
  Link,
  Navigate,
  useNavigate,
  useParams
} from "react-router-dom"
import CloudformationOutputs from '../CloudformationOutputs.json'
import FormatQuestionAnswer from '../FormatQuestionAnswer'
import Button from '../formfields/Button'
import { GlobalContext } from '../GlobalContext'
import Card from '../ui-elements/Card'
import GreenAlert from '../ui-elements/GreenAlert'
import Modal from '../ui-elements/Modal'
import RedAlert from '../ui-elements/RedAlert'
import Spinner from '../ui-elements/Spinner'
import YellowAlert from '../ui-elements/YellowAlert'
import { getDate } from '../utils/getDate'
import { getStartAndEndTimestampsForDay } from '../utils/getStartAndEndTimestampsForDay'
import ListAnswerNotes from './ListAnswerNotes'
import FormatChecklistTimeWindow from './FormatChecklistTimeWindow'

type Props = {
}

type ChecklistPeriodsProps = {
  thisCheckPeriod: ObjectStringKeyAnyValue
  thisChecklistQuestions: ObjectStringKeyAnyValue[] | null
}

type ChecklistQuestionsProps = {
  question: ObjectStringKeyAnyValue
  thisCheckPeriod: ObjectStringKeyAnyValue
}


function ChecklistQuestions({
  question,
  thisCheckPeriod
}: ChecklistQuestionsProps) {
  const {
    tableData,
    currentOrganisation
  } = useContext(GlobalContext)

  const [showImageModal, setShowImageModal] = useState(false)
  const [showNotesModal, setShowNotesModal] = useState(false)

  return <div className={`flex flex-col gap-2`}>




    {tableData && tableData.ChecklistAnswers && Object.keys(tableData.ChecklistAnswers).map((key, index) => {
      const thisAnswer = tableData.ChecklistAnswers[key]
      if (thisAnswer.ChecklistPeriodId === thisCheckPeriod.Id && thisAnswer.QuestionId === question.Id) {
        return <div key={index}>

          {showImageModal && <Modal showCloseButton={true} setShowModal={setShowImageModal}>
            <div className={`flex flex-col gap-3 items-center`}>
              <img
                onClick={() => setShowImageModal(false)}
                src={`https://${CloudformationOutputs.ImageBucketName}.s3.eu-west-1.amazonaws.com/${currentOrganisation}/${thisAnswer.ImageFileName}`} alt=''
                className={`rounded max-h-72 my-2`}
              />
              <Button
                onClick={() => setShowImageModal(false)}
                text={'Close'}
              />
            </div>
          </Modal>}


          {showNotesModal && <Modal showCloseButton={true} setShowModal={setShowNotesModal}>
            <ListAnswerNotes answerNotes={thisAnswer.AnswerNotes} />
          </Modal>}



          <div
            className={`w-full rounded bg-white flex flex-row items-stretch`}
          >


            {!thisAnswer.Pending ?
              <div className={`w-16 bg-brandblue text-white rounded-l py-4 px-2 flex flex-col justify-center items-center text-2xl font-bold`}>✓</div>
              :
              <div className={`w-16 bg-red-400 text-white rounded-l py-4 px-2 flex flex-col justify-center items-center text-2xl font-bold`}>?</div>
            }


            <div className={`w-full flex flex-row justify-between items-center`}>

              <div className={`w-full p-2 text-sm flex flex-col justify-center items-start gap-1`}>


                {question.QuestionFormat !== 'FridgeTemperature' && <h5 className={`text-lg font-righteous text-brandblue`}>{question.QuestionText}</h5>}


                {question.QuestionFormat === 'FridgeTemperature' && <h5 className={`text-lg font-righteous text-brandblue`}>Temperature check for fridge: {question.FridgeId} </h5>}





                {thisAnswer.Pending ?
                  <p className={`text-xs text-gray-600 italic`}>Not answered</p>
                  :
                  <div className={`w-full flex flex-col items-start text-left gap-1`}>



                    <FormatQuestionAnswer
                      questionAnswer={thisAnswer}
                      isTemperature={question.QuestionFormat === 'FridgeTemperature' ? true : false}
                    />


                    <p className={`text-xs text-gray-600 italic`}>Answered by
                      {!thisAnswer.CognitoUserId && !thisAnswer.GuestUserName && ` guest user `}
                      {!thisAnswer.CognitoUserId && thisAnswer.GuestUserName && ` guest user ${thisAnswer.GuestUserName} `}
                      {!thisAnswer.GuestUserName && <>
                        {thisAnswer.CognitoUserId &&
                          tableData &&
                          tableData.Users &&
                          tableData.Users[thisAnswer.CognitoUserId] &&
                          tableData.Users[thisAnswer.CognitoUserId]['UserName'] ?
                          `  ${tableData.Users[thisAnswer.CognitoUserId]['UserName']} ` :
                          ` user id: #${thisAnswer.CognitoUserId}`}

                      </>}
                      {thisAnswer.AnswerTimestamp && ` at ${getDate(thisAnswer.AnswerTimestamp, 'formattedDateAndTime')}`}
                    </p>





                    <div className={`w-full flex flex-col gap-2 items-start justify-start`}>
                      {thisAnswer && thisAnswer['AnswerAlerts'] &&
                        Object.keys(thisAnswer['AnswerAlerts']).length > 0 &&
                        <div
                          className={`w-full flex flex-col gap-2 items-start justify-start`}>
                          {Object.values(thisAnswer['AnswerAlerts']).map((alert: any, index: number) => {
                            return <RedAlert
                              key={index}
                              icon={true}
                              fullwidth={true}
                              alignment={`left`}
                              size={`small`}
                            >
                              {alert.message}
                            </RedAlert>
                          })}
                        </div>}


                    </div>




                    {/* <p className={`text-xs text-gray-600`}>Format: {question.QuestionFormat} Id: {question.Id}  order:[{question.QuestionOrder}]</p> */}

                  </div>}

                {thisAnswer.AnswerNotes && Object.keys(thisAnswer.AnswerNotes).length > 0 && <YellowAlert
                  onClick={() => setShowNotesModal(true)}
                  icon={true}
                  fullwidth={true}
                  alignment={`left`}
                  size={`small`}
                >Click to show {Object.keys(thisAnswer.AnswerNotes).length} note{Object.keys(thisAnswer.AnswerNotes).length > 1 ? 's' : ''}
                </YellowAlert>}



              </div>

              {thisAnswer && thisAnswer.ImageFileName && currentOrganisation &&
                <img src={`https://${CloudformationOutputs.ImageBucketName}.s3.eu-west-1.amazonaws.com/${currentOrganisation}/${thisAnswer.ImageFileName}`} alt=''
                  className={`rounded bg-gray-400 min-h-8 min-w-8 max-h-20 m-2 hover:opacity-80 cursor-pointer`}
                  onClick={() => setShowImageModal(true)}
                />}
            </div>


          </div>
        </div>
      }
    })}


  </div>
}



function ChecklistPeriods({
  thisCheckPeriod,
  thisChecklistQuestions
}: ChecklistPeriodsProps) {

  const {
    tableData,
  } = useContext(GlobalContext)

  return <div>
    <h4 className={`mt-3 font-righteous text-2xl font-brandblue`}>Check period: 
    <FormatChecklistTimeWindow thisCheckPeriod={thisCheckPeriod} />
    </h4>
    <div className={`w-full flex flex-col gap-2`}>


      {thisChecklistQuestions && thisChecklistQuestions.length === 0 && <RedAlert icon={true}>No questions have been set up for this checklist</RedAlert>}
      {thisChecklistQuestions && thisChecklistQuestions.length > 0 && <div className={`mt-3 w-full flex flex-col gap-2`}>
        {thisChecklistQuestions.map((question: ObjectStringKeyAnyValue, index: number) => {
          return <ChecklistQuestions
            key={index}
            question={question}
            thisCheckPeriod={thisCheckPeriod}
          />
        })}
      </div>
      }




    </div>
  </div>
}

function ChecklistResultsDay({
}: Props) {

  const {
    tableData,
    sendMessageToWebsocket
  } = useContext(GlobalContext)

  const navigate = useNavigate()
  const { checklistId, year, month, day } = useParams()
  const [displayDate, setDisplayDate] = useState<string | number | null>(null)
  const [checklistPeriods, setChecklistPeriods] = useState<null | ObjectStringKeyAnyValue>(null)
  const [thisChecklistQuestions, setThisChecklistQuestions] = useState<null | Array<ObjectStringKeyAnyValue>>(null)
  const [hasDataBeenFetched, setHasDataBeenFetched] = useState(false)

  // tableData && console.log(tableData['ChecklistPeriods'])

  useEffect(() => {
    if (tableData && month && year && day) {
      if (!hasDataBeenFetched) {
        fetchData()
      }
    }
  }, [tableData, year, month, day, hasDataBeenFetched])

  useEffect(() => {
    // refetch data if date changes
    setHasDataBeenFetched(false)
  }, [displayDate])

  useEffect(() => {
    if (year && month && day) {
      const dateString = `${year}-${`${month}`.padStart(2, '0')}-${`${day}`.padStart(2, '0')}`
      setDisplayDate(dateString)
    } else {
      const dateString = getDate(Date.now(), 'datePickerFormat')
      setDisplayDate(dateString)
    }
  }, [year, month, day])


  useEffect(() => {
    if (tableData && displayDate) {
      // set checklist periods
      const timeZone = tableData.Organisation.TimeZone || 'Europe/London'
      const { startTimestamp, endTimestamp } = getStartAndEndTimestampsForDay(displayDate, timeZone)
      const newChecklistPeriods: ObjectStringKeyAnyValue = {}

      if (tableData['ChecklistPeriods']) {
        for (const key in tableData['ChecklistPeriods']) {
          const thisCheckPeriod = tableData['ChecklistPeriods'][key]

          if (
            thisCheckPeriod['ChecklistId'] === checklistId
            && parseInt(thisCheckPeriod['StartTimeStamp']) >= startTimestamp
            && parseInt(thisCheckPeriod['StartTimeStamp']) <= endTimestamp
          ) {
            // console.log(`
            // check period ${key}
            // ${getDate(startTimestamp)} - ${getDate(endTimestamp)}
            // ${getDate(thisCheckPeriod['StartTimeStamp'])} - ${getDate(thisCheckPeriod['EndTimeStamp'])}
            // `)
            newChecklistPeriods[key] = tableData['ChecklistPeriods'][key]
          }
        }
        setChecklistPeriods(newChecklistPeriods)
      }

      // set questions
      if (tableData['ChecklistQuestions']) {
        let questionsArray: any[] = Object.values(tableData['ChecklistQuestions']).filter((question: any) => question.ChecklistId === checklistId)
        questionsArray.sort((a, b) => parseInt(a.QuestionOrder) - parseInt(b.QuestionOrder))
        setThisChecklistQuestions(questionsArray)
      }

    }
  }, [tableData, displayDate])




  const fetchData = () => {
    const payload = JSON.stringify({
      "action": "checklists",
      "subAction": "fetchChecklistInfoByTimePeriod",
      "checklistId": checklistId,
      "month": month,
      "year": year,
      "day": day
    })
    console.log('🎣 fetching...')
    sendMessageToWebsocket(payload)
    setHasDataBeenFetched(true)
  }

  const changeDate = (dateString: string) => {
    const dateParts = dateString.split('-')
    const year = parseInt(dateParts[0])
    const month = parseInt(dateParts[1])
    const day = parseInt(dateParts[2])
    navigate(`/checklists/${checklistId}/results/${year}/${month}/${day}`)
  }



  return <div className={`w-full flex flex-col items-center`}>


    <div className={`w-full bg-white flex flex-col items-center`}>
      <div className={`max-w-3xl w-full p-5 flex flex-row gap-4 items-start  justify-between`}>
        <div>
          <h3 className={`font-righteous text-3xl font-brandblue`}>
            {checklistId && tableData?.Checklists[checklistId].ChecklistName ? tableData.Checklists[checklistId].ChecklistName : "Untitled Checklist"}
          </h3>
          <div className={`mt-3 w-full flex flex-row gap-4 items-center`}>
            <p>Select date:</p>
            <input
              className={`rounded px-2 py-1 bg-gray-200 border border-gray-400 text-gray-600 text-sm`}
              type={'date'}
              onChange={(e) => changeDate(e.target.value)}
              value={displayDate || ''}
            />
          </div>
        </div>
        <Button
          text={'Month view'}
          internalLinkUrl={`/checklists/${checklistId}/results/${year}/${month}`}
          variant={'primary'}
          size={'small'}
          fullwidth={false}
        />
      </div>
    </div>


    {!hasDataBeenFetched && <div className={`max-w-3xl w-full p-5 flex flex-col gap-4`}><Spinner>Loading...</Spinner></div>}

    {hasDataBeenFetched && <>

      {checklistId &&
        tableData &&
        tableData.Checklists &&
        tableData.Checklists[checklistId] ?
        <div className={`max-w-3xl w-full p-5 flex flex-col gap-4`}>




          {checklistPeriods && Object.keys(checklistPeriods).length === 0 && <RedAlert>No check periods for this date</RedAlert>}

          {checklistPeriods && Object.keys(checklistPeriods).length > 0 && <div className={`w-full flex flex-col gap-4`}>
            {Object.keys(checklistPeriods).map((key, index) => {
              const thisCheckPeriod = checklistPeriods[key]
              return <ChecklistPeriods
                key={index}
                thisCheckPeriod={thisCheckPeriod}
                thisChecklistQuestions={thisChecklistQuestions}
              />
            })}
          </div>}


        </div>
        :
        <div className={`max-w-3xl w-full p-5 flex flex-col gap-2`}>
          <RedAlert>Checklist ID: {checklistId} not found</RedAlert>
        </div >
      }
    </>}
  </div>
}
export default ChecklistResultsDay