import React, { useState, useEffect, useRef } from 'react'
import { GlobalContext } from './GlobalContext'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
  json,
} from "react-router-dom"

// utilities
import CloudformationOutputs from './CloudformationOutputs.json'
import { checkIfSubscriptionExpiringWithoutPayment } from './utils/checkIfSubscriptionExpiringWithoutPayment'
import { publish } from './utils/pubsub'
// UI Elements
import Footer from './ui-elements/Footer'
import Header from './ui-elements/Header'
import HorizontalScroll from './ui-elements/HorizontalScroll'
// account setup
import AssignFridgesToGroups from './account-setup-flow/AssignFridgesToGroups'
import CheckTimes from './account-setup-flow/CheckTimes'
import Complete from './account-setup-flow/Complete'
import GetStarted from './account-setup-flow/GetStarted'
import Groups from './account-setup-flow/Groups'
import IntroBanner from './account-setup-flow/IntroBanner'
// assets app
import AssetMap from './asset-management/AssetMap'
import EditAsset from './asset-management/EditAsset'
import ListAssets from './asset-management/ListAssets'
import ServiceRecord from './asset-management/ServiceRecord'
// first aid app
import ListIncidents from './firstaid/ListIncidents'
import SingleIncident from './firstaid/SingleIncident'
// fridges
import FridgeProfiles from './fridges/FridgeProfiles'
import ListFridges from './fridges/ListFridges'
import ListGroups from './fridges/ListGroups'
import ListReadings from './fridges/ListReadings'
// shared components
import AppTermsDialog from './shared-components/AppTermsDialog'
import CardJustAdded from './shared-components/CardJustAdded'
import Dashboard from './shared-components/Dashboard'
import FeatureNotEnabled from './shared-components/FeatureNotEnabled'
import ListUsers from './shared-components/ListUsers'
import Payments from './shared-components/Payments'
import SplashPage from './shared-components/SplashPage'
import SubscriptionExpired from './shared-components/SubscriptionExpired'
import SubscriptionExpiring from './shared-components/SubscriptionExpiring'
import SuperAdmin from './shared-components/SuperAdmin'
import Notifications from './shared-components/Notifications'
import LoginOptions from './shared-components/LoginOptions'
import StickerPacks from './fridges/StickerPacks'
//Checklists
import ChecklistDetails from './checklists/ChecklistDetails'
import MyChecklists from './checklists/MyChecklists'
import ChecklistResultsDay from './checklists/ChecklistResultsDay'
import ChecklistResultsMonth from './checklists/ChecklistResultsMonth'
import NewChecklistWrapper from './checklists/wrapper/ChecklistSetupWrapper'
import SharepointConnector from './integrations/SharepointConnector'
import { unsubscribe } from 'diagnostics_channel'
import ChecklistOptions from './shared-components/ChecklistOptions'
import RedAlert from './ui-elements/RedAlert'
import AccessDenied from './ui-elements/AccessDenied'
import PresetChecklists from './checklists/PresetChecklists'
import AddStickerPack from './fridges/AddStickerPack'
import AddEditFridge from './fridges/AddEditFridge'
import AccountPlans from './fridges/AccountPlans'
import DailyChecklistSummary from './shared-components/DailyChecklistSummary'





type Organisations = null | ObjectStringKeyAnyValue

function App() {

  const [loggedIn, setLoggedIn] = useState<boolean | string>(false)
  const [idToken, setIdToken] = useState<string | null>(null)
  const [accessToken, setAccessToken] = useState<string | null>(null)
  const [socket, setSocket] = useState<WebSocket | null>(null)
  const [connectionState, setConnectionState] = useState('disconnected')
  const [orgSettings, setOrgSettings] = useState('disconnected')
  const [userData, setUserData] = useState<ObjectStringKeyAnyValue | null>(null)
  const [currentOrganisation, setCurrentOrganisation] = useState<string | null>(null)
  const [logoUrl, setLogoUrl] = useState<string>(`/logo-white-no-strapline.svg`)
  const loginUrl = `https://${CloudformationOutputs.UserPoolDomain}.auth.${CloudformationOutputs.AWSRegion}.amazoncognito.com/login?client_id=${CloudformationOutputs.UserPoolClientId}&response_type=token&scope=email+openid+phone+profile&redirect_uri=${window.location.origin}/dashboard/`
  const logoutUrl = `https://${CloudformationOutputs.UserPoolDomain}.auth.${CloudformationOutputs.AWSRegion}.amazoncognito.com/logout?client_id=${CloudformationOutputs.UserPoolClientId}&logout_uri=${window.location.origin}/`
  const WebSocketURI = CloudformationOutputs.WebSocketURI
  const [tableData, setTableData] = useState<ObjectStringKeyAnyValue | null>(null)
  const [loggedInUserRole, setLoggedInUserRole] = useState<string | null>(null)
  const tableDataRef = useRef<any>(null)
  const [fridges, setFridges] = useState<ObjectStringKeyAnyValue | null>(null)
  const [currentStickerPack, setCurrentStickerPack] = useState<ObjectStringKeyAnyValue | null>(null)
  const [isAccountSetup, setIsAccountSetup] = useState<boolean | null>(null)
  const [activeFeatures, setActiveFeatures] = useState<{ [key: string]: any }>({})
  const [expiredFeatures, setExpiredFeatures] = useState<{ [key: string]: any }>({})
  const [agreementNeeded, setAgreementNeeded] = useState<{ [key: string]: any } | null>(null)
  const [subscriptionHasBeenChecked, setSubscriptionHasBeenChecked] = useState<boolean>(false)
  const [subscriptionExpired, setSubscriptionExpired] = useState<boolean>(false)
  const [subscriptionExpiringWithoutPayment, setSubscriptionExpiringWithoutPayment] = useState<boolean>(false)
  const [checklistSetupFields, setChecklistSetupFields] = useState<ObjectStringKeyAnyValue | null>(null)
  const [checklistQuestionFields, setChecklistQuestionFields] = useState<ObjectStringKeyAnyValue | null>(null)

  // console.log(tableData)
  // tableData && tableData.Checklists && console.log(Object.keys(tableData.Checklists).length)

  useEffect(() => {
    checkForToken()
    setConnectionState('tryToConnect')

  }, [])

  useEffect(() => {
    if (currentStickerPack) {
      localStorage.setItem('currentStickerPack', JSON.stringify(currentStickerPack))
    } else {
      if (localStorage.getItem('currentStickerPack')) {
        setCurrentStickerPack(JSON.parse(`${localStorage.getItem('currentStickerPack')}`))
      }
    }
  }, [currentStickerPack])

  useEffect(() => {
    switch (connectionState) {
      case 'closed':
      case 'error':
        setConnectionState('waitThenReconnect')
        setTimeout(() => {
          setConnectionState('tryToConnect')
        }, 200)
        break;
      case 'tryToConnect':
        setConnectionState('connecting')
        const newSocket = new WebSocket(WebSocketURI)
        newSocket.onopen = () => {
          setSocket(newSocket)
          setConnectionState('connected')
        }
        newSocket.onclose = () => {
          console.log("🔌  socket closed")
          setConnectionState('closed')
        }
        newSocket.onerror = () => {
          setConnectionState('error')
          console.log("🔌 socket error")
        }
        break;

    }

  }, [connectionState])

  useEffect(() => {
    if (tableData) {

      checkIfSubscriptionExpiringWithoutPayment(tableData, subscriptionHasBeenChecked, setSubscriptionExpiringWithoutPayment, setSubscriptionHasBeenChecked)

      // set logged in user role
      tableDataRef.current = tableData
      if (tableData.Users && userData) {
        const loggedInUserId = userData['cognito:username']
        const role = tableData.Users[loggedInUserId] ? tableData.Users[loggedInUserId].Role : null
        setLoggedInUserRole(role)
      }
      // filter all fridge assets into their own state variable
      if (tableData.Assets) {
        const allAssetsArray = Object.values(tableData.Assets)
        const justFridges: ObjectStringKeyAnyValue = {}
        allAssetsArray.forEach((asset: any) => {
          if (asset.AssetType === 'Fridge') {
            justFridges[asset.Id] = asset
          }
        })
        setFridges(justFridges)

        // check if all setup steps are complete
        if (tableData.Groups && Object.keys(tableData.Groups).length > 0 && Object.keys(justFridges).length > 0) {
          setIsAccountSetup(true)
        } else {
          setIsAccountSetup(false)
        }
      }
    }

  }, [tableData, userData])

  useEffect(() => {
    // handle incoming messages
    if (socket !== null) {
      actOnMessage()
    }
  }, [socket])

  useEffect(() => {
    if (idToken && accessToken && socket) {
      // sending tokens to websocket!
      const payloadObj: { [index: string]: string } = {
        action: "login",
        idToken: idToken,
        accessToken: accessToken
      }
      const selectedOrgId = localStorage.getItem("selectedOrgId")
      if (selectedOrgId) {
        payloadObj['selectedOrgId'] = selectedOrgId
      }
      const payload = JSON.stringify(payloadObj)
      sendMessageToWebsocket(payload)
    }
  }, [idToken, accessToken, socket, loggedIn, currentOrganisation])

  const checkForToken = () => {
    //console.log('checking for a token')
    const localIdToken = localStorage.getItem("localIdToken")
    const localAccessToken = localStorage.getItem("localAccessToken")
    const queryStringParams = window.location.hash.replace("#", "").split(/[&=]+/)

    let idToken = null
    let accessToken = null

    const indexOfIdToken = queryStringParams.indexOf('id_token')
    if (indexOfIdToken >= 0) {
      idToken = queryStringParams[indexOfIdToken + 1]
    }
    const indexOfAccessToken = queryStringParams.indexOf('access_token')
    if (indexOfAccessToken >= 0) {
      accessToken = queryStringParams[indexOfAccessToken + 1]
    }


    if (idToken && accessToken) {
      //console.log('login pending')
      setLoggedIn('pending')
      setIdToken(idToken)
      setAccessToken(accessToken)
      localStorage.setItem('localIdToken', idToken)
      localStorage.setItem('localAccessToken', accessToken)
      window.location.href = '/dashboard'

    } else if (localIdToken && localAccessToken) {
      //console.log('logged in earlier')
      setIdToken(localIdToken)
      setAccessToken(localAccessToken)
    } else {
      //console.log('not logged in')
      window.location.href = loginUrl
    }
  }

  const switchCurrentOrganisation = (organisationId: string) => {
    //console.log(`Organization id change requested to ${organisationId}`)
    localStorage.setItem("selectedOrgId", organisationId)
    setCurrentOrganisation(organisationId)
    setTableData(null)

    if (idToken && accessToken && socket) {
      const payloadObj: { [index: string]: string } = {
        action: "login",
        idToken: idToken,
        accessToken: accessToken,
        selectedOrgId: organisationId
      }
      const payload = JSON.stringify(payloadObj)
      sendMessageToWebsocket(payload)
    }

  }

  const sendMessageToWebsocket = async (payload: string | object) => {
    if (socket) {
      if (typeof (payload) != 'string') {
        payload = JSON.stringify(payload)
      }
      // console.log(`sending to websocket: ${JSON.parse(payload).action}`)
      socket.send(payload)
    } else {
      console.log('🚫  Can not send to web socket')
    }
  }

  function checkPaymentDetailsAndRedirect(redirectUrl: string) {
    const lastPaymentTimestamp = localStorage.getItem('lastPaymentRequestTimestamp');
    const currentTime = Date.now();

    // Check if the timestamp exists and is less than 2 minutes ago
    if (lastPaymentTimestamp) {
      const timeElapsed = currentTime - parseInt(lastPaymentTimestamp, 10);

      // If less than 10 minutes have passed, do nothing
      if (timeElapsed < 2 * 60 * 1000) {
        console.log('Payment details were requested less than 2 minutes ago. No redirect.');
        return;
      }
    }
    // If the timestamp doesn't exist or more than 2 minutes have passed, update the timestamp and redirect
    window.location.href = redirectUrl;
  }

  const mergeTableData = (newData: ObjectStringKeyAnyValue) => {
    const oldTableData = tableDataRef.current
    if (oldTableData) {
      let mergedTableData = { ...oldTableData };
      Object.keys(newData).forEach(key => {
        // Directly assigning the value from newData, overriding the oldData value if key exists in both
        mergedTableData[key] = newData[key];
      });
      setTableData(mergedTableData)
    } else {
      setTableData(newData)
    }
  }

  const actOnMessage = () => {
    if (socket && socket.onmessage === null) {
      socket.onmessage = ({ data }: any) => {
        if (data) {
          const action = JSON.parse(data).action
          const payload = JSON.parse(data).payload
          publish(action, payload)
          // console.log(`📨 A message has been received: ${action }`)
          switch (action) {
            case 'error':
              console.log(`🚫  Error: ${JSON.stringify(payload)}`)
              break

            case 'autherror':
              console.log('🚫  Authentication error - logging you out')
              window.location.href = logoutUrl
              break

            case 'loggedIn':
              //console.log("🪵 logged in")
              const loginDataBody = JSON.parse(data).body
              //console.log(loginDataBody)
              setLoggedIn(true)
              setUserData(loginDataBody.idTokenDecoded.payload)
              setOrgSettings(loginDataBody.settings)

              setActiveFeatures(loginDataBody['activeFeatures'] || {})
              setExpiredFeatures(loginDataBody['expiredFeatures'] || {})

              const loggedInOrgId = loginDataBody.organisationId
              if (loggedInOrgId) {
                setCurrentOrganisation(loggedInOrgId)
                localStorage.setItem('selectedOrgId', loggedInOrgId)
              }

              let requestAssets = JSON.stringify({
                action: "fetchMultiTableData",
                tablesToFetch: ['Assets', 'AssetProfiles', 'Groups', 'Checklists', 'ChecklistQuestions', 'Users'],
              })
              sendMessageToWebsocket(requestAssets)
              break


            case 'loginFailed':
              window.location.href = loginUrl
              break

            case 'loginOKButNoOrganisation':
              window.localStorage.setItem('loggedInNoOrgId', data)
              window.location.href = '/noorg'
              break

            case 'returnMultiTableData':
              //console.log(`❎ Multiple tables received`)
              //console.log(`❎ Multiple tables received: ${JSON.stringify(payload)}`)

              mergeTableData(payload)

              break

            case 'returnSingleTableData':
              const newKey = Object.keys(payload)[0]
              const newValue = payload[newKey]
              const newTableData = { ...tableDataRef.current }
              newTableData[newKey] = newValue
              setTableData(newTableData)
              //console.log(`❎  updated table received: ${Object.keys(payload)[0]} at time: ${Date.now()}`)
              break

            case 'tableUpdateItemAdded':
            case 'tableUpdateItemModified':
              console.log(`❎  updated item received`)
              const tableToUpdate = payload['tableName']
              const itemToUpdate = payload['keys'][Object.keys(payload['keys'])[0]]

              // console.log(tableToUpdate)
              // console.log(payload)

              const updatedTableData = { ...tableDataRef.current }
              updatedTableData[tableToUpdate] ||= {}
              updatedTableData[tableToUpdate][itemToUpdate] = payload['newRecord']
              setTableData(updatedTableData)
              break
            case 'tableUpdateItemRemoved':
              const tableToRemoveItemFrom = payload['tableName']
              const itemToRemoveKey = payload['keys'][Object.keys(payload['keys'])[0]]
              const tableDataWithItemRemoved = { ...tableDataRef.current }
              if (tableDataWithItemRemoved[tableToRemoveItemFrom] && tableDataWithItemRemoved[tableToRemoveItemFrom][itemToRemoveKey]) {
                delete tableDataWithItemRemoved[tableToRemoveItemFrom][itemToRemoveKey]
              }
              setTableData(tableDataWithItemRemoved)
              break

            case 'ok':
              //console.log('super admin command ok')
              break

            case 'agreementNeeded':
              console.log("📝 Electronic agreement needed")
              console.log(payload)
              setAgreementNeeded(payload)
              break;
            case 'agreementSaved':
              console.log("📝 Electronic agreement saved")
              console.log(payload)
              setAgreementNeeded(null)
              break;
            case 'whiteLabelDetails':
              if (payload.logoUrl) {
                setLogoUrl(payload.logoUrl)
              }
              break
            case 'addUserResponse':
            case 'deleteUserResponse':
            case 'switchUserRoleResponse':
              //console.log(`❎  user table update: ${JSON.stringify(payload)}`)
              const newUserKey = Object.keys(payload)[0]
              const newUserValue = payload[newUserKey]
              const newUserData = { ...tableDataRef.current }
              newUserData[newUserKey] = newUserValue
              setTableData(newUserData)
              break
            case 'paymentMethodNeeded':
              checkPaymentDetailsAndRedirect(payload['portalUrl'])
              break;

            case 'incidentRiddorResponse':
            case 'addItemSignedUploadURL':
            case 'updateItemSignedUploadURL':
            case 'addItemResponse':
            case 'updateItemResponse':
            case 'deleteItemResponse':
            case "documentReadyForDownloadResponse":
            case 'assignStickerPackIdToOrgResponse':
            case 'assignFridgesToGroupsResponse':
            case 'makeStickerPackResponse':
            case 'generateFridgeGroupsResponse':
            case 'returnCheckingTimes':
            case 'returnFridgeGroups':
            case 'notificationPreferencesResponse':
            case 'loginPreferencesResponse':
            case 'makeAuditSheetResponse':
            case 'checklistUpdatedResponse':
            case 'checklistQuestionUpdatedResponse':
            case 'checklistQuestionDeletedResponse':
            case 'qrCodeGenerated':
            case 'checklistDeletedResponse':
            case 'changeQuestionOrderResponse':
            case 'returnSharepointLoginUrl':
            case 'returnSharepointColumnsForList':
            case 'returnSharepointSites':
            case 'returnSharepointMappings':
            case 'returnSharepointConnectionCheckResult':
            case 'returnSharepointListsForSite':
            case 'returnSharepointMappingSaved':
            case 'updateChecklistOptionsResponse':
            case 'presetChecklistAdded':
            case 'returnDailyChecklistSummary':
            case 'cloneOrgResponse':
              // handled when pub sub or when table data is updated
              break

            default:
              console.log(`⛔️ A message has been received but the action (${action}) is invalid`)
              //console.log(`A message has been received but the action is invalid. ${data}`)
              break
          }
        }
      }
    }
  }

  const handleLogout = () => {
    setLoggedIn(false)
    setUserData(null)
    localStorage.removeItem('localIdToken')
    localStorage.removeItem('localAccessToken')
    window.location.href = logoutUrl
  }


  if (loggedIn && loggedInUserRole === 'user') {
    return <div className={`flex flex-col min-h-screen justify-between`}>
      <div className={`flex flex-col flex-grow`}>
        <Router>
          <Header handleLogout={handleLogout} logoUrl={logoUrl} />
          <div className={`flex flex-col items-center`}>
            <div className={`max-w-3xl w-full p-5 flex flex-col gap-5 `}>
              <AccessDenied />
            </div>
          </div>
        </Router>
      </div>
      <Footer
      />
    </div>
  }


  return <div className={`flex flex-col min-h-screen justify-between`}>
    <div className={`bg-gray-200 flex flex-col flex-grow`}>
      <GlobalContext.Provider value={{
        tableData,
        userData,
        fridges,
        currentOrganisation,
        switchCurrentOrganisation,
        sendMessageToWebsocket,
        checklistSetupFields,
        setChecklistSetupFields,
        checklistQuestionFields,
        setChecklistQuestionFields,
        activeFeatures,
        expiredFeatures,
        loggedIn,
        loginUrl,
        orgSettings
      }}>
        {subscriptionExpired && <SubscriptionExpired />}
        {subscriptionExpiringWithoutPayment && <SubscriptionExpiring
          setSubscriptionExpiringWithoutPayment={setSubscriptionExpiringWithoutPayment}
        />}
        <Router>
          <Header handleLogout={handleLogout} logoUrl={logoUrl} />
          {agreementNeeded && <AppTermsDialog details={agreementNeeded} sendMessageToWebsocket={sendMessageToWebsocket} />}
          <Routes>
            <Route path='/*' element={<SplashPage />} />
            <Route path='/sharepoint' element={<SharepointConnector />} />
            <Route path='/hs' element={<HorizontalScroll />} />
            <Route path='/dashboard' element={<Dashboard />} />

            <Route
              path='/listreadings'
              element={
                activeFeatures.hasOwnProperty('Fridges') ?
                  <ListReadings
                    setTableData={setTableData}
                  /> :
                  <AccessDenied />
              } />

            <Route
              path='/listreadings/:groupId'
              element={
                activeFeatures.hasOwnProperty('Fridges') ?
                  <ListReadings
                    setTableData={setTableData}
                  /> :
                  <AccessDenied />
              } />

            <Route path='/users' element={<ListUsers loggedInUserRole={loggedInUserRole} />} />

            <Route path='/loginoptions' element={<LoginOptions />} />


            <Route
              path='/assets'
              element={<ListAssets />} />


            <Route
              path='/map'
              element={<AssetMap />} />

            <Route
              path='/asset'
              element={<EditAsset />} />


            <Route
              path='/notifications'
              element={
                activeFeatures.hasOwnProperty('Fridges') ?
                  <Notifications /> :
                  <AccessDenied />}
            />

            <Route
              path='/asset/:Id'
              element={<EditAsset />} />

            <Route
              path='/fridges'
              element={
                activeFeatures.hasOwnProperty('Fridges') ?
                  <ListFridges /> :
                  <AccessDenied />
              } />

            <Route
              path='/fridges/add-edit/'
              element={
                activeFeatures.hasOwnProperty('Fridges') ?
                  <AddEditFridge /> :
                  <AccessDenied />
              } />

            <Route
              path='/fridges/add-edit/:fridgeId'
              element={
                activeFeatures.hasOwnProperty('Fridges') ?
                  <AddEditFridge /> :
                  <AccessDenied />
              } />

            <Route
              path='/incidents'
              element={<ListIncidents />} />

            <Route
              path='/incident/:Id'
              element={<SingleIncident />} />

            <Route
              path='/servicerecord/:Id'
              element={<ServiceRecord />} />

            <Route
              path='/payments'
              element={<Payments />} />

            <Route
              path='/superadmin'
              element={<SuperAdmin handleLogout={handleLogout} />} />

            <Route
              path='/stickerpacks'
              element={<StickerPacks />} />

            <Route
              path='/card_added'
              element={<CardJustAdded />} />

            <Route
              path='checklists'
              element={<MyChecklists />} />

            <Route
              path='add-sticker-pack'
              element={<AddStickerPack />} />

            <Route
              path='checklists/presets'
              element={<PresetChecklists />} />
            <Route
              path='checklists/:checklistId'
              element={<ChecklistDetails />} />
            <Route
              path='checklist-options'
              element={<ChecklistOptions />} />
            <Route
              path='/daily-checklist-summary/'
              element={<DailyChecklistSummary />} />
            <Route
              path='/daily-checklist-summary/:year/:month'
              element={<DailyChecklistSummary />} />
            <Route
              path='checklists/:checklistId/results/:year/:month'
              element={<ChecklistResultsMonth />} />
            <Route
              path='checklists/:checklistId/results/:year/:month/:day'
              element={<ChecklistResultsDay />} />
            <Route
              path='checklists/:checklistId/settings/'
              element={<NewChecklistWrapper showForm='settings' />} />
            <Route
              path='checklists/:checklistId/questions/:questionId/'
              element={<NewChecklistWrapper showForm='questions' />} />

          </Routes>
        </Router>
      </GlobalContext.Provider>

    </div>
    <Footer
    />
  </div>

}

export default App
