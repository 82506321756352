import React, { useEffect, useState, useContext } from 'react'
import {
    BrowserRouter as Router,
    Link,
    Navigate,
    useNavigate,
    useParams
} from "react-router-dom"
import { GlobalContext } from '../../GlobalContext'
import { subscribe } from "../../utils/pubsub"
import Button from '../../formfields/Button'
import Card from '../../ui-elements/Card'
import RedAlert from '../../ui-elements/RedAlert'
import Spinner from '../../ui-elements/Spinner'
import Modal from '../../ui-elements/Modal'
import ChecklistSettings from '../new-settings-steps/ChecklistSettings'
import { validateChecklistSettingsForm } from '../checklist-utils/validateChecklistSettingsForm'
import ChecklistQuestions from '../new-questions-steps/ChecklistQuestions'
import { validateChecklistQuestionsForm } from '../checklist-utils/validateChecklistQuestionsForm'


type Props = {
    showForm?: string
}

function NewChecklistWrapper({
    showForm
}: Props) {

    const {
        tableData,
        currentOrganisation,
        sendMessageToWebsocket,
        // checklistSetupFields,
        // setChecklistSetupFields,
    } = useContext(GlobalContext)


    const { checklistId, questionId } = useParams()
    const navigate = useNavigate()
    const [isNewChecklist, setIsNewChecklist] = useState(false)
    const [thisChecklist, setThisChecklist] = useState<ObjectStringKeyAnyValue | null>(null)
    const [savingChecklist, setSavingChecklist] = useState<string | null>(null)
    const [savingQuestion, setSavingQuestion] = useState<string | null>(null)
    const [settingsFormFields, setSettingsFormFields] = useState<ObjectStringKeyAnyValue>({})
    const [settingsFormErrors, setSettingsFormErrors] = useState<ObjectStringKeyAnyValue>({})
    const [questionsFormFields, setQuestionsFormFields] = useState<ObjectStringKeyAnyValue>({})
    const [questionsFormErrors, setQuestionsFormErrors] = useState<ObjectStringKeyAnyValue>({})


    useEffect(() => {
        if (tableData && tableData.Checklists && tableData.ChecklistQuestions) {
            if (Object.keys(settingsFormFields).length === 0) {
                populateSettingsFromData()
            }
            if (Object.keys(questionsFormFields).length === 0) {
                populateQuestionsFromData()
            }
        }
    }, [tableData, checklistId, questionId])


    useEffect(() => {
        // once the checklist ID appears in the table data, set savingChecklist to null
        if (tableData && savingChecklist && tableData.Checklists && tableData.Checklists[savingChecklist]) {
            setSavingChecklist(null)
            navigate(`/checklists/${savingChecklist}`)
        }
    }, [tableData, savingChecklist])

    useEffect(() => {
        // once the question ID appears in the table data, set savingQuestion to null
        if (tableData && savingQuestion && tableData.ChecklistQuestions && tableData.ChecklistQuestions[savingQuestion]) {
            setSavingChecklist(null)
            navigate(checklistId ? `/checklists/${checklistId}` : `/checklists/`)
        }
    }, [tableData, savingQuestion])


    const populateSettingsFromData = () => {
        if (checklistId === 'new') {
            setIsNewChecklist(true)
        } else if (checklistId && tableData.Checklists[checklistId]) {
            setThisChecklist({ ...tableData.Checklists[checklistId] })
            setSettingsFormFields({ ...tableData.Checklists[checklistId] })
        }
    }

    const populateQuestionsFromData = () => {
        console.log('🦎')
        if (questionId && tableData.ChecklistQuestions[questionId]) {
            console.log('💟')
            setQuestionsFormFields({ ...tableData.ChecklistQuestions[questionId] })
        } else if (questionId && checklistId) {
            console.log('🔰')
            setQuestionsFormFields({ Id: questionId, ChecklistId: checklistId })
        }
    }

    const validateSettingsAndSave = () => {
        let isFormValid = validateChecklistSettingsForm(settingsFormFields, setSettingsFormErrors)
        if (isFormValid) {
            saveChecklistSettings(settingsFormFields)
        }
    }

    const validateQuestionsAndSave = () => {
        let isFormValid = validateChecklistQuestionsForm(questionsFormFields, setQuestionsFormErrors)
        if (isFormValid) {
            saveChecklistQuestions(questionsFormFields)
        }
    }

    const saveChecklistSettings = (formData: ObjectStringKeyAnyValue) => {
        const randomChecklistId = `${Date.now()}`
        let idToLookForInResponse: string = checklistId === 'new' ? `${randomChecklistId}` : `${checklistId}`

        setSavingChecklist(idToLookForInResponse)
        const payload: ObjectStringKeyAnyValue = {
            action: "checklists",
            subAction: "addOrUpdate",
            payload: formData
        }
        // if it's a brand new checklist, add a new random checklist to check returned data for
        if (checklistId === 'new') {
            payload['payload']['newChecklistId'] = randomChecklistId
        }

        sendMessageToWebsocket(JSON.stringify(payload))
    }

    const saveChecklistQuestions = (formData: ObjectStringKeyAnyValue) => {
        if (formData.Id && formData.ChecklistId) {
            setSavingQuestion(formData.Id)
            const payload = JSON.stringify({
                action: "checklists",
                subAction: "addOrUpdateQuestion",
                payload: formData
            })
            sendMessageToWebsocket(payload)
        } else {
            console.log('⛔️ No question ID or checklist Id supplied')
        }
    }


    return <div className={`flex-1 h-full flex flex-col justify-between gap-2 bg-gray-200`}>
        <div className={`flex flex-col gap-2 items-center`}>
            <div className={`w-full bg-white flex flex-row gap-4 items-center justify-center border-b-2 border-gray-300`}>
                <div className={`max-w-3xl w-full p-5 flex flex-col gap-2 items-center justify-center text-center`}>
                    <h3 className={`font-righteous text-3xl font-brandblue`}>
                        {thisChecklist ? thisChecklist.ChecklistName : 'New Checklist'}
                    </h3>
                    {showForm && <p className={`text-md`}>{showForm}</p>}


                    {/* <p className={`text-xs`}>
                        thisChecklist: {thisChecklist &&
                            JSON.stringify(thisChecklist).replaceAll(',', ', ').replaceAll('%', '% ')}</p> */}

                    {/* <p className={`text-xs`}>
                        settingsFormFields: {settingsFormFields ?
                            JSON.stringify(settingsFormFields).replaceAll(',', ', ').replaceAll('%', '% ')
                            : 'null'}</p> */}

                    {/* <p className={`text-xs`}>
                        Checklist Form errors: {settingsFormErrors ?
                            JSON.stringify(settingsFormErrors).replaceAll(',', ', ').replaceAll('%', '% ')
                            : 'null'}</p> */}

                    {/* <p className={`text-xs`}>
                        Questions State: {questionsFormFields ?
                            JSON.stringify(questionsFormFields).replaceAll(',', ', ')
                            : 'null'}</p> */}

                    {/* <p className={`text-xs`}>
                        Questions errors: {questionsFormErrors ?
                            JSON.stringify(questionsFormErrors).replaceAll(',', ', ')
                            : 'null'}</p> */}

                </div>
            </div>
            <div className={`max-w-3xl w-full p-5`}>

                {savingQuestion && <Modal
                    showCloseButton={true}
                    setShowModal={setSavingQuestion}>
                    <Spinner><p>Saving question...</p></Spinner>
                </Modal>}

                {savingChecklist && <Modal
                    showCloseButton={true}
                    setShowModal={setSavingChecklist}>
                    <Spinner><p>Saving checklist...</p></Spinner>
                </Modal>}

                {!tableData && <Spinner><p>Loading...</p></Spinner>}
                {tableData && !isNewChecklist && !thisChecklist && <RedAlert>Sorry - no checklist with ID "{checklistId}" has been found</RedAlert>}

                {tableData && (thisChecklist || isNewChecklist) &&
                    <>
                        {showForm === 'settings' &&
                            <ChecklistSettings
                                thisChecklist={thisChecklist}
                                settingsFormFields={settingsFormFields}
                                settingsFormErrors={settingsFormErrors}
                                setSettingsFormFields={setSettingsFormFields}
                                setSettingsFormErrors={setSettingsFormErrors}
                            />
                        }
                        {showForm === 'questions' &&
                            <ChecklistQuestions
                                thisChecklist={thisChecklist}
                                questionsFormFields={questionsFormFields}
                                questionsFormErrors={questionsFormErrors}
                                setQuestionsFormFields={setQuestionsFormFields}
                                setQuestionsFormErrors={setQuestionsFormErrors}
                            />
                        }
                    </>
                }




            </div>
        </div>

        <div className={`bg-white flex flex-row gap-4 items-center justify-center border-t-2 border-gray-300`}>
            <div className={`max-w-3xl w-full p-5 flex flex-row gap-5`}>

                <Button
                    internalLinkUrl={checklistId && checklistId !== 'new' ? `/checklists/${checklistId}` : `/checklists/`}
                    text={'Back'}
                    variant={'primary'}
                    size={'big'}
                />
                {showForm === 'settings' && <Button
                    onClick={validateSettingsAndSave}
                    text={'Save'}
                    variant={'primary'}
                    size={'big'}
                />}
                {showForm === 'questions' && <Button
                    onClick={validateQuestionsAndSave}
                    text={'Save'}
                    variant={'primary'}
                    size={'big'}
                />}

            </div>
        </div>
    </div>
}
export default NewChecklistWrapper